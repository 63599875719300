.chonky-chonkyRoot {
  background-color: #1f252f !important;
  color: white !important;
  width: 100%;
}

.MuiInputBase-root {
  color: white !important;
  border: 1px solid #232531 !important;
  padding-left: 0.3em !important;
  font-size: 0.9em !important;
  background-color: #12161d !important;
}

.MuiTypography-colorTextSecondary,
.MuiButton-root,
.MuiButton-root:hover {
  color: white !important;
}

.MuiButton-root.Mui-disabled {
  color: #707070 !important;
}
.chonky-infoText {
  display: none;
}
.chonky-navbarWrapper {
  padding-bottom: 2px !important;
}
.chonky-baseButton {
  height: 10px !important;
  line-height: 10px !important;
}
div[class^="previewFile"] {
  background-color: #2a2c39 !important;
  box-shadow: none !important;
}
div[class^="previewFile"]:hover {
  background-color: #db0a41 !important;
}
div[class^="listFileEntry"] {
  font-size: 10px !important;
  line-height: 11px !important;
}

.MuiButton-label {
  color: white !important;
  font-size: 10px;
}

div[class^="fileIcon"] {
  opacity: 0 !important;
}
div[class^="gridFileEntryName"] {
  font-size: 10px;
}
div[class^="folderBackSideTop"]::after {
  border-color: transparent transparent transparent transparent;
}
div.MuiListSubheader-root.chonky-browserMenuTooltip.MuiListSubheader-sticky.MuiListSubheader-gutters {
  display: none !important;
}

::-webkit-scrollbar:horizontal {
  height: 5px;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track:horizontal {
  /* -webkit-box-shadow: inset 0 0 6px #16181f; */
  -webkit-border-radius: 10px;
  border-radius: 10px;
  /* background-color: #16181f;  */
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background-color: #8d1237;
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

/* Handle */
::-webkit-scrollbar-thumb:horizontal {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background-color: #8d1237;
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
